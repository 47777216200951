<template>
  <div class="cart-floor__wrapper">
    <slot name="header"></slot>
    <div class="cart-floor__scroll j-cart-floor__scroll">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script steup></script>

<style lang="less" scoped>
.cart-floor {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: url(https://img.ltwebstatic.com/images3_ccc/2024/09/02/25/1725271407aa5311592e8413d6658acfb49289a640.webp)  no-repeat center top / 100% auto #F0F0F0;
    border-radius: 12/37.5rem 12/37.5rem 0 0;
  }
  &__scroll {
    margin: 0 6/37.5rem;
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    flex: 1;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    overscroll-behavior-x: contain;
    &::-webkit-scrollbar {
      width: 0
    }
  }
}
</style>

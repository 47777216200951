<template>
  <div class="cart-floor__skeleton">
    <div class="header">
      <div class="header-title"></div>
      <div class="header-close"></div>
    </div>
    <div
      v-for="n in 3"
      :key="n"
      class="group"
    >
      <div class="group-header">
        <div class="group-checked"></div>
        <div class="group-title"></div>
      </div>
      <div
        v-for="i in 2"
        :key="i"
        class="item"
      >
        <div class="item-checked"></div>
        <div class="item-img"></div>
        <div class="item-info">
          <div class="item-title"></div>
          <div class="item-attr"></div>
          <div class="item-tag"></div>
          <div class="item-bottom">
            <div class="item-price"></div>
            <div class="item-qty"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.cart-floor__skeleton {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40/37.5rem;
    flex-shrink: 0;
  }
  .header-title {
    width: 50%;
    height: 19/37.5rem;
    background-color: #f2f2f2;
  }
  .header-close {
    position: absolute;
    right: 4/37.5rem;
    top: 4/37.5rem;
    width: 14/37.5rem;
    height: 14/37.5rem;
    background-color: #f2f2f2;
  }
  .group {
    background-color: #f9f9f9;
    border-radius: 12/37.5rem;
    margin: 0 6/37.5rem;
  }
  .group + .group {
    margin-top: 6/37.5rem;
  }
  .group-header {
    display: flex;
    align-items: center;
    height: 32/37.5rem;
    padding: 0 12/37.5rem;
  }
  .group-checked {
    width: 16/37.5rem;
    height: 16/37.5rem;
    background-color: #f2f2f2;
    margin-right: 12/37.5rem;
  }
  .group-title {
    height: 17/37.5rem;
    background-color: #f2f2f2;
    width: 40%;
  }
  .item {
    display: flex;
    align-items: flex-start;
    min-height: 74/37.5rem;
    padding: 0 12/37.5rem;
    margin-top: 10/37.5rem;
    margin-bottom: 10/37.5rem;
  }
  .item + .item {
    margin-top: 20/37.5rem;
  }
  .item-checked {
    margin-top: 31/37.5rem;
    width: 16/37.5rem;
    height: 16/37.5rem;
    background-color: #f2f2f2;
    margin-right: 12/37.5rem;
  }
  .item-img {
    width: 74/37.5rem;
    height: 74/37.5rem;
    background-color: #f2f2f2;
    margin-right: 10/37.5rem;
  }
  .item-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6/37.5rem;
  }
  .item-title {
    width: 90%;
    height: 14/37.5rem;
    background-color: #f2f2f2;
  }
  .item-attr {
    width: 50%;
    height: 20/37.5rem;
    background-color: #f2f2f2;
  }
  .item-tag {
    width: 70%;
    height: 16/37.5rem;
    background-color: #f2f2f2;
  }
  .item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10/37.5rem;
  }
  .item-price {
    width: 30%;
    height: 20/37.5rem;
    background-color: #f2f2f2;
  }
  .item-qty {
    width: 30%;
    height: 20/37.5rem;
    background-color: #f2f2f2;
  }
}
</style>

<template>
  <div class="cart-floor__group">
    <div class="cart-floor__group-header">
      <slot name="title"></slot>
    </div>
    <div class="cart-floor__group-content">
      <slot name="item"></slot>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped lang="less">
.cart-floor {
  &__group {
    border-radius: 12/37.5rem;
    background-color: #fff;
    margin-bottom: 6/37.5rem;
  }
  &__group-header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 var(--item-gap);
    border-radius: 12/37.5rem;
    background: #fff;
    z-index: 2;
    overflow: hidden;
  }
  &__group-content {
    border-radius: .32rem;
  }
}
</style>
